import React from 'react'
import BannerTranslator from '../components/BannerTranslator'
import SignupTranslator from '../components/SignupTranslator'
import TestimonialTranslator from '../components/TestimonialTranslator'
import WhyTranslator from '../components/WhyTranslator'

const Translator: React.FC = () => {
  return (
    <>
      <BannerTranslator />
      <WhyTranslator />
      <SignupTranslator />
      <TestimonialTranslator />
    </>
  )
}

export default Translator
