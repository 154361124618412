import React from 'react'
import styled, { keyframes } from 'styled-components'

import { FiCheck } from 'react-icons/fi'
import { PiTranslateLight } from 'react-icons/pi'

const SwitchAnimated = ({ isHome = false }: { isHome?: boolean }) => {
  return (
    <SwitchContainer
      style={{
        backgroundImage: isHome
          ? undefined
          : `url(${require('../../assets/switch_image.png')})`,
      }}
    >
      <SwitchCircle isHome={isHome}>
        {isHome ? (
          <FiCheck color='white' size={20} />
        ) : (
          <PiTranslateLight color='#484848' size={20} />
        )}
      </SwitchCircle>
    </SwitchContainer>
  )
}

export default SwitchAnimated

const transition = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(120%);
  }
`

const SwitchContainer = styled.div`
  width: 79.99px;
  height: 40.4px;
  border-radius: 79.99px;
  background-size: contain;
  display: flex;
  padding: 0 2px;
  align-items: center;
  background-color: #3a9eef;
`
const SwitchCircle = styled.div<{ isHome?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.94px;
  height: 33.94px;
  border-radius: 33.94px;
  background-color: ${({ isHome }) =>
    isHome ? 'var(--hard-blue)' : '#3a9eef'};

  animation: ${transition} 2s alternate infinite;
`
