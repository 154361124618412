import React from 'react'
import styled from 'styled-components'

import { PiTranslate } from 'react-icons/pi'
import { GoArrowUpRight } from 'react-icons/go'

import { DefaultFields } from '../../services/strapiService'

interface AboutUs extends Omit<DefaultFields, 'iconName'> {}

const AboutUs = ({ about }: { about: AboutUs }) => {
  return (
    <Container>
      <Content>
        <Title>{about?.title}</Title>
        <Description>{about?.subtitle}</Description>
        <ButtonWrapper>
          <ActionButton href='#'>
            {about?.buttonLabelRequestQuote} <GoArrowUpRight size={24} />
          </ActionButton>
          <SecondaryButton href='#'>
            {about?.buttonLabelBeTranslator} <PiTranslate size={24} />
          </SecondaryButton>
        </ButtonWrapper>
      </Content>
      <ImageContainer>
        <StyledImage
          src='/about/about_banner.jpeg'
          alt='Grupo de pessoas olhando para a câmera'
        />
      </ImageContainer>
    </Container>
  )
}

export default AboutUs

// Styled Components
const Container = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: var(--soft-blue);
  padding: 0;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 15px;
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 41px 20px 200px;

  @media (max-width: 1024px) {
    padding-left: 30px;
  }

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`

const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 36.48px;
  text-align: left;
  color: white;

  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 28px;
  }
`

const Description = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 38.7px;
  text-align: left;
  color: white;

  margin-bottom: 30px;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ActionButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px;
  background-color: white;
  border-radius: 4px;

  font-family: 'Inter', sans-serif;
  text-decoration: none;
  font-size: 21;
  font-weight: 400;
  color: var(--hard-blue);

  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--green);
    color: white;
  }

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

const SecondaryButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 16px;
  background-color: transparent;
  border: none;

  color: white;
  font-family: 'Inter', sans-serif;
  text-decoration: none;
  font-weight: 400;
  font-size: 21;

  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: white;
    color: var(--hard-blue);
  }

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

const StyledImage = styled.img`
  height: 800px;
  object-fit: contain;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    border-radius: 4px;
  }
`
