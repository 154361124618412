import styled, { keyframes } from 'styled-components';

// Animação para o texto rotativo
const rotateWords = keyframes`
  0% { opacity: 0; transform: translateY(20px); }
  10% { opacity: 1; transform: translateY(0); }
  90% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
`;

export const HeaderContainer = styled.header`
  width: 100%;
  background-color: var(--grey);
  font-family: 'Inter', sans-serif;
`;

export const TopBar = styled.div`
  background-color: var(--hard-blue);
  color: white;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export const LanguageSelector = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const LanguageButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;

  img {
    width: 24px;
    height: auto;
  }

  span {
    font-size: 14px;
    color: white;
  }
`;

export const LanguageDropdown = styled.div`
  position: absolute;
  top: 30px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
`;

export const LanguageItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--soft-blue);
  }

  img {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
`;

export const ContactInfos = styled.div`
  display: flex;
`;

export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  background-color: var(--grey);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const RightText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const MenuToggle = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    color: var(--hard-blue);
  }
`;

export const NavLinks = styled.ul<{ isMenuOpen: boolean }>`
  display: flex;
  list-style: none;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    display: ${(props) => (props.isMenuOpen ? 'block' : 'none')};
    width: 100%;
    padding: 20px;
    background: var(--grey);
  }

  a {
    color: var(--black);
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;

    &:hover {
      color: var(--green);
    }

    @media (max-width: 768px) {
      padding: 10px 0;
      border-bottom: 1px solid var(--hard-blue);
    }
  }
`;

export const RotatingWord = styled.span`
  display: inline-block;
  animation: ${rotateWords} 3s linear infinite;
`;

export const Logo = styled.img`
  width: 180px;
`;

export const ButtonsWrapper = styled.div<{ isMenuOpen: boolean }>`
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    padding: 20px 0;
    display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
  }
`;

export const Button = styled.button<{ primary?: boolean }>`
  background-color: ${(props) =>
    props.primary ? 'var(--hard-blue)' : 'transparent'};
  color: ${(props) => (props.primary ? 'white' : 'var(--hard-blue)')};
  border: ${(props) => (props.primary ? 'none' : '1px solid var(--hard-blue)')};
  padding: 16px 21px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.primary ? 'var(--soft-blue)' : 'var(--hard-blue)'};
    color: ${(props) => (props.primary ? 'white' : 'white')};
  }

  svg {
    font-size: 18px;
  }
`;
