import React from 'react'
import styled from 'styled-components'
import { DefaultFields } from '../../services/strapiService'

interface MoreAboutUs extends Pick<DefaultFields, 'title' | 'subtitle'> {
  description: string
}

const MoreAbout = ({ moreAbout }: { moreAbout: MoreAboutUs }) => {
  return (
    <Container>
      <LogoWrapper>
        <Logo src='/gl-logo.png' />
      </LogoWrapper>
      <Content>
        <Title>{moreAbout?.title}</Title>
        <Description>{moreAbout?.subtitle}</Description>
        <Description>{moreAbout?.description}</Description>
      </Content>
    </Container>
  )
}

export default MoreAbout

// Styled Components
const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a6fef;
  padding: 60px 40px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  @media (max-width: 1024px) {
    width: 80%;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 50%;
    height: 50%;
  }
`

const Content = styled.div`
  max-width: 600px;

  @media (max-width: 768px) {
    text-align: center;
  }
`

const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 36.48px;
  text-align: left;
  color: white;

  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`

const Description = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 29px;
  font-weight: 400;
  line-height: 37.41px;
  text-align: left;
  color: white;

  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`
