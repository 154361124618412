import React, { memo } from 'react'
import InputMask from 'react-input-mask'

import { InputWrapper, Label, InputBase, ErrorMessage } from './styles'

export type InputSubscripeProps = {
  label?: string
  placeholder?: string
  type: 'text' | 'email' | 'password'
  register: any
  icon?: React.ReactNode
  errorMessage?: string
  mask?: string
  disabled?: boolean
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
} & React.InputHTMLAttributes<HTMLInputElement>

const Input = React.forwardRef(
  (
    {
      label,
      placeholder,
      type,
      register,
      icon,
      errorMessage,
      mask,
      onFocus,
      onBlur,
      disabled,
    }: InputSubscripeProps,
    ref
  ) => {
    return (
      <>
        <InputWrapper>
          <Label>{label}</Label>
          <div className='element'>{icon}</div>

          {mask ? (
            <InputMask mask={mask} maskChar=' ' ref={ref} {...register}>
              {(inputProps: InputSubscripeProps) => (
                <InputBase
                  disabled={disabled}
                  {...inputProps}
                  errorInput={!!errorMessage}
                  placeholder={placeholder}
                  type={type}
                  {...register}
                />
              )}
            </InputMask>
          ) : (
            <InputBase
              ref={ref}
              disabled={disabled}
              errorInput={!!errorMessage}
              type={type}
              placeholder={placeholder}
              onFocus={onFocus}
              onBlur={onBlur}
              {...register}
            />
          )}
        </InputWrapper>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </>
    )
  }
)

export default memo(Input)
