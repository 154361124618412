import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --blue: #3A6FEF;
    --green: #61CBC6;
    --hard-blue: #0024BE;
    --soft-blue: #338EE9;
    --grey: #ECECEC;
    --grey-black: #333333;
    --white-transparent:#ffffff40;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Inter', sans-serif;
    background-color: var(--grey);
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    font-family: 'Inter', sans-serif;
  }
`;

export {};
