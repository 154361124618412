import React, { useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import styled from 'styled-components'

import Header from './components/Header'
import Modal from './components/Modal'
import Rodape from './components/Rodape'
import About from './pages/About'
import Home from './pages/Homepage/Home'
import Industries from './pages/Industries'
import IndustrieDetailsPage from './pages/IndustriesDetails'
import Login from './pages/Login'
import SolutionDetailsPage from './pages/SolutionDetails'
import Solutions from './pages/Solutions'
import Translator from './pages/Translator'
import { GlobalStyles } from './styles/globalStyles'
import { LocaleProvider } from './contexts/useLocale'
import { BsWhatsapp } from 'react-icons/bs'

const WHATSHAPP_LINK = 'https://wa.me/5511959788985'

const App: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <Router>
      <LocaleProvider>
        <Header
          setModalOpen={function (isOpen: boolean): void {
            throw new Error('Function not implemented.')
          }}
        />
        <GlobalStyles />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/sobre' element={<About />} />
          <Route path='/solucoes' element={<Solutions />} />
          <Route path='/solucoes/:type' element={<SolutionDetailsPage />} />
          <Route path='/industrias/:type' element={<IndustrieDetailsPage />} />
          <Route path='/industrias' element={<Industries />} />
          <Route path='/tradutores' element={<Translator />} />
          <Route path='/login' element={<Login />} />
        </Routes>
        <FixedButton onClick={() => (window.location.href = WHATSHAPP_LINK)}>
          <BsWhatsapp size={32} />
        </FixedButton>
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
        <Rodape />
      </LocaleProvider>
    </Router>
  )
}

export default App

const FixedButton = styled.button`
  position: fixed;
  display: flex;
  align-items: center;
  gap: 1em;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border: none;
  padding: 20px 20px;
  z-index: 1000;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1eaa51;
  }

  @media (max-width: 768px) {
    right: 10px;
    bottom: 10px;
    padding: 10px 15px;
    font-size: 14px;
  }
`
