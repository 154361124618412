import React, { useEffect, useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import styled from 'styled-components'
import { useLocale } from '../../contexts'
import { FaqSectionTexts, fetchFaq } from '../../services/strapiService'
import { PiTranslate } from 'react-icons/pi'
import { GoArrowUpRight } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import Modal from '../Modal'

const FAQData: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [faq, setFaq] = React.useState<FaqSectionTexts>({} as FaqSectionTexts)

  const { locale } = useLocale()
  const navigate = useNavigate()

  const toggleAccordion = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  const handleTranslatorButtonClick = () => {
    navigate('/tradutores')
  }

  const handleRequestQuoteButtonClick = () => {
    setIsModalOpen(true)
  }

  const getFaq = async () => {
    try {
      const { data } = await fetchFaq(locale)

      setFaq(data?.data)
    } catch (error) {
      console.error('Erro ao buscar dados do faq:', error)
    }
  }

  useEffect(() => {
    getFaq()
  }, [locale])

  return (
    <FAQContainer>
      <LeftSection>
        <h2>{faq?.title}</h2>
        <p>{faq?.subtitle}</p>

        <ButtonsWrapper>
          <Button primary onClick={handleRequestQuoteButtonClick}>
            {faq?.buttonLabelRequestQuote}
            <GoArrowUpRight />
          </Button>
          <Button onClick={handleTranslatorButtonClick}>
            {faq?.buttonLabelBeTranslator}
            <PiTranslate />
          </Button>
        </ButtonsWrapper>
      </LeftSection>
      <RightSection>
        {faq?.faq?.map((item, index) => (
          <AccordionBox key={index} onClick={() => toggleAccordion(index)}>
            <Question>
              {item.question}
              {activeIndex === index ? <AiOutlineMinus /> : <AiOutlinePlus />}
            </Question>
            {activeIndex === index && <Answer>{item.answer}</Answer>}
          </AccordionBox>
        ))}
      </RightSection>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </FAQContainer>
  )
}

export default FAQData

// Styled Components

const FAQContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: #f8f9fa;
  justify-content: space-around;
  padding: 80px 120px;

  @media (max-width: 1024px) {
    padding: 40px 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 40px;

  h2 {
    font-size: 34px;
    font-weight: 500;
    color: var(--grey-black);
  }

  p {
    margin-top: 10px;
    font-size: 16px;
    color: var(--grey-black);
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    max-width: 100%;

    h2 {
      font-size: 28px;
    }

    p {
      font-size: 16px;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
    margin-bottom: 40px;
  }

  @media (max-width: 425px) {
    flex-direction: column;
  }
`

export const Button = styled.button<{ primary?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.primary ? 'var(--hard-blue)' : 'white'};
  color: ${(props) => (props.primary ? 'white' : 'hard-blue')};
  border: ${(props) =>
    props.primary ? '1px solid white' : '1px solid var(--hard-blue)'};

  gap: 20px;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;

  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 200;

  &:hover {
    background-color: ${(props) => (props.primary ? 'blue' : 'blue')};
    color: ${(props) => (props.primary ? 'white' : 'white')};
    border: ${(props) => (props.primary ? '1px solid white' : 'none')};
    backdrop-filter: blur(2px);
  }
`

const RightSection = styled.div`
  flex: 1.5;
`

const AccordionBox = styled.div`
  background-color: white;
  border: 1px solid var(--grey);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 25px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--white-transparent);
  }

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    font-size: 24px;
    color: var(--grey-black);
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`

const Question = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const Answer = styled.p`
  font-size: 16px;
  color: var(--grey-black);
  margin-top: 10px;
  line-height: 1.6;
`
