import React, {
  useMemo,
  useState,
  ReactNode,
  useContext,
  createContext,
} from 'react'

interface LocaleContextData {
  locale: string
  changeLocale: (newLocale: string) => void
}

const LocaleContext = createContext<LocaleContextData>({} as LocaleContextData)

interface LocaleProviderProps {
  children: ReactNode
}

export const LocaleProvider = ({ children }: LocaleProviderProps) => {
  const localeStorage = localStorage.getItem('locale')

  const [locale, setLocale] = useState(localeStorage ?? 'pt-BR')

  const changeLocale = React.useCallback(async (newLocale: string) => {
    localStorage.setItem('locale', newLocale)
    setLocale(newLocale)
  }, [])

  const value = useMemo(
    () => ({
      locale,
      changeLocale,
    }),
    [locale, changeLocale]
  )

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  )
}

export const useLocale = () => useContext(LocaleContext)
