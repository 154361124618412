import React from 'react'
import styled, { keyframes } from 'styled-components'

import { FiAward } from 'react-icons/fi'
import { LiaAtomSolid } from 'react-icons/lia'
import { RxLightningBolt } from 'react-icons/rx'
import { BsBookmarkCheck, BsLayers } from 'react-icons/bs'

import {
  CultureSectionTexts,
  fetchCultureSectionTexts,
} from '../../services/strapiService'
import { useLocale } from '../../contexts'

const Why = ({ blueBackground = false }: { blueBackground?: boolean }) => {
  const [cultureWhyText, setCultureWhyText] = React.useState<
    CultureSectionTexts[]
  >([])

  const icons: Record<string, React.JSX.Element> = {
    atom: (
      <LiaAtomSolid size={42} color={blueBackground ? 'white' : '#0024BE'} />
    ),
    bookmark: (
      <BsBookmarkCheck size={42} color={blueBackground ? 'white' : '#0024BE'} />
    ),
    flash: (
      <RxLightningBolt size={42} color={blueBackground ? 'white' : '#0024BE'} />
    ),
    medal: <FiAward size={42} color={blueBackground ? 'white' : '#0024BE'} />,
    papers: <BsLayers size={42} color={blueBackground ? 'white' : '#0024BE'} />,
  }

  const { locale } = useLocale()

  const getCultureWhyText = async () => {
    try {
      const { data } = await fetchCultureSectionTexts(locale)

      setCultureWhyText(data?.data)
    } catch (error) {
      console.error('Erro ao carregar informações de cultura', error)
    }
  }

  React.useEffect(() => {
    getCultureWhyText()
  }, [locale])

  return (
    <Wrapper blueBackground={blueBackground}>
      <CardsWrapper>
        <ClientTracker>
          {cultureWhyText?.map((item) => (
            <Card key={item?.title} blueBackground={blueBackground}>
              <IconWrapper>
                {icons[item?.iconName]}
                <Title blueBackground={blueBackground}>{item.title}</Title>
              </IconWrapper>
              <Description blueBackground={blueBackground}>
                {item?.subtitle}
              </Description>
            </Card>
          ))}
          {cultureWhyText?.map((item) => (
            <Card key={item?.title} blueBackground={blueBackground}>
              <IconWrapper>
                {icons[item?.iconName]}
                <Title blueBackground={blueBackground}>{item.title}</Title>
              </IconWrapper>
              <Description blueBackground={blueBackground}>
                {item?.subtitle}
              </Description>
            </Card>
          ))}
        </ClientTracker>
      </CardsWrapper>
    </Wrapper>
  )
}

export default Why

// Styled Components
const Wrapper = styled.div<{ blueBackground?: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: ${({ blueBackground }) =>
    blueBackground ? 'var(--soft-blue)' : 'white'};
  padding: 40px 0px;
`

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-70%);
  }
`

const ClientTracker = styled.div`
  display: flex;
  gap: 60px;
  width: max-content;

  animation: ${scroll} 30s linear infinite;
`;

const CardsWrapper = styled.div`
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 100px;
    width: 200px;
    position: absolute;
    z-index: 2;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

const Card = styled.div<{ blueBackground?: boolean }>`
  background-color: ${({ blueBackground }) =>
    blueBackground ? 'var(--soft-blue)' : 'white'};
  max-width: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--hard-blue);
  align-items: flex-start;
  justify-content: flex-start;
  transition: transform 0.3s ease;
  overflow: hidden;
  position: relative;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 768px) {
    svg {
      width: 28px;
      height: 28px;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`

const Title = styled.h3<{ blueBackground?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  font-family: 'Manrope', sans-serif;
  color: ${({ blueBackground }) =>
    blueBackground ? 'white' : 'var(--hard-blue)'};
  text-transform: uppercase;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const Description = styled.p<{ blueBackground?: boolean }>`
  font-size: 21px;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  color: ${({ blueBackground }) => (blueBackground ? 'white' : '#4d4d4d')};
  font-family: 'Manrope', sans-serif;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`
