import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { PiTranslate } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { GoArrowUpRight } from 'react-icons/go'

import Modal from '../Modal'
import {
  DefaultFields,
  fetchSolutionsText,
  Solution,
} from '../../services/strapiService'
import { useLocale } from '../../contexts'

export const SolutionsBannerDetails = ({
  solution,
}: {
  solution: Solution
}) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [solutionsText, setSolutionsText] = useState<DefaultFields>(
    {} as DefaultFields
  )

  const { locale } = useLocale()

  const getSolutionsText = async () => {
    try {
      const {
        data: { data },
      } = await fetchSolutionsText(locale)

      setSolutionsText(data)
    } catch (error) {
      console.error('Erro ao buscar dados de textos soluções:', error)
    }
  }

  const imageUrl = solution.image?.url
    ? `https://engaging-chickens-0267a7699e.media.strapiapp.com/${solution.image.url}`
    : '/path/to/default-image.png'

  const handleRequestQuoteButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleNavigateToRegisterTranslator = () => {
    navigate('/tradutores')
  }

  useEffect(() => {
    getSolutionsText()
  }, [locale])

  return (
    <SolutionsBannerDetailsWrapper>
      <SolutionsBannerImage src={imageUrl} />
      <SolutionsBannerTexts>
        <SolutionsBannerTitle>{solution?.title}</SolutionsBannerTitle>
        <SolutionsBannerSubtitle>
          {solution?.description}
        </SolutionsBannerSubtitle>

        <RequestButtonsWrapper>
          <RequestButton onClick={handleRequestQuoteButtonClick}>
            {solutionsText?.buttonLabelRequestQuote} <GoArrowUpRight />
          </RequestButton>
          <RequestButton primary onClick={handleNavigateToRegisterTranslator}>
            {solutionsText?.buttonLabelBeTranslator} <PiTranslate />
          </RequestButton>
        </RequestButtonsWrapper>
        <SolutionsBannerHow>{solution?.how}</SolutionsBannerHow>
      </SolutionsBannerTexts>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        serviceType={{ value: solution.title, label: solution.title }}
      />
    </SolutionsBannerDetailsWrapper>
  )
}

const SolutionsBannerDetailsWrapper = styled.div`
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

const SolutionsBannerImage = styled.img`
  width: 50%;
  height: 800px;
  object-fit: cover;

  @media (max-width: 500px) {
    width: 100%;
    height: 400px;
  }
`

const SolutionsBannerTexts = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 40px;

  background-color: var(--hard-blue);

  @media (max-width: 768px) {
    padding: 30px 20px;
    width: 100%;

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 20px;
    }
  }
`

const SolutionsBannerTitle = styled.h1`
  font-size: 28px;
  font-weight: 700;
  line-height: 36.12px;
  text-align: left;

  margin-bottom: 30px;

  color: white;
`

const SolutionsBannerSubtitle = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 36.12px;
  text-align: left;

  margin-bottom: 35px;

  color: white;
`

const SolutionsBannerHow = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 36.12px;
  text-align: left;

  margin-top: 35px;

  color: #61cbc6;
`

const RequestButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const RequestButton = styled.button<{ primary?: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.primary ? 'transparent' : 'white')};
  color: ${(props) => (props.primary ? 'white' : 'var(--hard-blue)')};
  border: ${(props) =>
    props.primary ? '1px solid white' : '0px solid var(--soft-blue)'};

  padding: 16px 21px;
  white-space: nowrap;
  font-size: 16px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--soft-blue);
    color: white;
  }

  svg {
    font-size: 18px;
  }
`
