import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { fetchIndustries, Industry } from '../../services/strapiService'
import { GoArrowUpRight } from 'react-icons/go'
import Modal from '../Modal'
import { PiTranslate } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { useLocale } from '../../contexts'

export const IndustriesBannerDetails = ({
  industryType,
}: {
  industryType?: string
}) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [industry, setIndustry] = useState<Industry>({} as Industry)
  const { locale } = useLocale()

  // Função para buscar dados da indústria específica
  const getIndustry = useCallback(async () => {
    if (industryType) {
      try {
        const { data } = await fetchIndustries(locale)

        const foundIndustry = data?.data?.find(
          (ind) => ind.type === industryType
        )

        if (foundIndustry) setIndustry(foundIndustry)
      } catch (error) {
        console.error('Erro ao buscar dados da indústria:', error)
      }
    }
  }, [locale])

  useEffect(() => {
    getIndustry()
  }, [locale])

  const imageUrl = industry.image?.url
    ? `https://engaging-chickens-0267a7699e.media.strapiapp.com/${industry.image.url}`
    : '/path/to/default-image.png'

  const handleRequestQuoteButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleNavigateToRegisterTranslator = () => {
    navigate('/tradutores')
  }

  return (
    <IndustriesBannerDetailsWrapper>
      <IndustriesBannerImage src={imageUrl} />
      <IndustriesBannerTexts>
        <IndustriesBannerTitle>{industry?.title}</IndustriesBannerTitle>
        <IndustriesBannerSubtitle>
          {industry?.description}
        </IndustriesBannerSubtitle>

        <RequestButtonsWrapper>
          <RequestButton onClick={handleRequestQuoteButtonClick}>
            Solicite um orçamento <GoArrowUpRight />
          </RequestButton>
          <RequestButton primary onClick={handleNavigateToRegisterTranslator}>
            Seja um tradutor <PiTranslate />
          </RequestButton>
        </RequestButtonsWrapper>
        <IndustriesBannerHow>{industry?.how}</IndustriesBannerHow>
      </IndustriesBannerTexts>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        serviceType={{ value: 'Consultoria', label: 'Consultoria' }}
      />
    </IndustriesBannerDetailsWrapper>
  )
}

const IndustriesBannerDetailsWrapper = styled.div`
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

const IndustriesBannerImage = styled.img`
  width: 50%;
  height: 800px;
  object-fit: cover;

  @media (max-width: 500px) {
    width: 100%;
    height: 400px;
  }
`

const IndustriesBannerTexts = styled.div`
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 40px;

  background-color: var(--hard-blue);

  @media (max-width: 768px) {
    padding: 30px 20px;
    width: 100%;

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 20px;
    }
  }
`

const IndustriesBannerTitle = styled.h1`
  font-size: 28px;
  font-weight: 700;
  line-height: 36.12px;
  text-align: left;

  margin-bottom: 34px;

  color: white;
`

const IndustriesBannerSubtitle = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 36.12px;
  text-align: left;

  margin-bottom: 42px;

  color: white;
`

const IndustriesBannerHow = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 36.12px;
  text-align: left;

  margin-top: 35px;

  color: #61cbc6;
`

const RequestButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const RequestButton = styled.button<{ primary?: boolean }>`
  width: 100%;
  background-color: ${(props) => (props.primary ? 'transparent' : 'white')};
  color: ${(props) => (props.primary ? 'white' : 'var(--hard-blue)')};
  border: ${(props) =>
    props.primary ? '1px solid white' : '0px solid var(--soft-blue)'};

  padding: 16px 21px;
  white-space: nowrap;
  font-size: 16px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--soft-blue);
    color: white;
  }

  svg {
    font-size: 18px;
  }
`
