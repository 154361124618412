import React, { useEffect, useState } from 'react'
import AboutUs from '../components/AboutUs'
import MoreAbout from '../components/MoreAbout'
import Solutions from '../components/SolutionsBox'
import Values from '../components/Values'
import Why from '../components/Why'
import { useLocale } from '../contexts'
import { fetchValuesText, ValuesText } from '../services/strapiService'
import WorkProcess from '../components/WorkProcess'

const About: React.FC = () => {
  const [valuesText, setValuesText] = useState<ValuesText>({} as ValuesText)

  const { locale } = useLocale()

  const getValuesText = async () => {
    try {
      const { data } = await fetchValuesText(locale)

      setValuesText(data?.data)
    } catch (error) {
      console.error('Erro ao buscar dados do banner do tradutor:', error)
    }
  }

  useEffect(() => {
    getValuesText()
  }, [locale])

  return (
    <>
      <AboutUs about={valuesText?.sections?.about} />
      <MoreAbout moreAbout={valuesText?.sections?.moreAbout} />
      <Values values={valuesText?.sections?.values} />
      <Why blueBackground />
      <Solutions lightBackground />
      <WorkProcess />
    </>
  )
}
export default About
