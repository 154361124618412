import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { TbReportSearch } from 'react-icons/tb'
import { FaChevronRight } from 'react-icons/fa'
import { BsFileEarmarkCheck } from 'react-icons/bs'
import { RiVerifiedBadgeLine } from 'react-icons/ri'

import {
  fetchHowItWorksSectionTexts,
  HowItWorksSectionTexts,
} from '../../services/strapiService'
import { useLocale } from '../../contexts'

const icons: Record<string, React.JSX.Element> = {
  'file-search': <TbReportSearch size={42} color='#0024BE' />,
  'file-check': <BsFileEarmarkCheck size={42} color='#0024BE' />,
  'bordered-check': <RiVerifiedBadgeLine size={42} color='#0024BE' />,
}

const WorkProcess: React.FC = () => {
  const [activeStep, setActiveStep] = useState(1)
  const [howItWorksTexts, setHowItWorksTexts] =
    useState<HowItWorksSectionTexts>({} as HowItWorksSectionTexts)

  const { locale } = useLocale()

  const getHowItWorksTexts = async () => {
    try {
      const { data } = await fetchHowItWorksSectionTexts(locale)

      setHowItWorksTexts(data?.data)
    } catch (error) {
      console.error('Erro ao carregar textos do processo de trabalho:', error)
    }
  }

  useEffect(() => {
    getHowItWorksTexts()
  }, [locale])

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep === 3 ? 1 : prevStep + 1))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <ProcessWrapper id='como-funciona'>
      <ProcessHeader>
        <h2>{howItWorksTexts?.title}</h2>
        <p>{howItWorksTexts?.subtitle}</p>
      </ProcessHeader>

      <StepsContainer>
        {howItWorksTexts?.list?.map((text, index) => (
          <React.Fragment key={text?.id + text?.id}>
            <StepItem isActive={index + 1 === activeStep}>
              <IconWrapper isActive={activeStep === activeStep}>
                {icons[text?.iconName]}
              </IconWrapper>

              <h3>{text.title}</h3>
              <p>{text.subtitle}</p>
            </StepItem>

            {index !== 2 && (
              <Arrow>
                <FaChevronRight size={62} />
              </Arrow>
            )}
          </React.Fragment>
        ))}
      </StepsContainer>
    </ProcessWrapper>
  )
}

export default WorkProcess

// Styled Components

const ProcessWrapper = styled.div`
  background: #f9f9f9;
  padding: 120px 90px;
  text-align: center;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`

const ProcessHeader = styled.div`
  margin-bottom: 30px;

  h2 {
    font-size: 38px;
    font-weight: 700;
    color: var(--grey-black);
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    color: #585757;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 21px;
      margin-bottom: 10px;
    }
  }
`

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 0;
  gap: 14px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StepItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => (props.isActive ? 'var(--hard-blue)' : 'white')};
  border: 0;
  padding: 40px;

  margin: 0;
  flex: 1;
  box-shadow: ${(props) =>
    props.isActive ? '0 0 15px rgba(51, 142, 233, 0.4)' : 'none'};
  transition: all 0.3s ease;

  h3 {
    font-size: 18px;
    margin-top: 10px;
    color: ${(props) => (props.isActive ? '#ffffff' : 'var(--hard-blue)')};
  }

  p {
    font-size: 14px;
    margin-top: 10px;
    color: ${(props) => (props.isActive ? 'white' : '#585757')};
  }

  a {
    color: ${(props) => (props.isActive ? 'white' : '#585757')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Arrow = styled.div`
  font-size: 24px;
  color: #ececec;
  margin: 0 15px;

  @media (max-width: 768px) {
    transform: rotate(90deg);
  }
`

const IconWrapper = styled.div<{ isActive: boolean }>`
  font-size: 50px;
  color: var(--hard-blue);
  background-color: #dcf0ff;
  padding: 15px;
  border-radius: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  transition: background 0.3s ease;
`
