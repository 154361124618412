import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import FAQData from '../components/FAQData'
import WorkProcess from '../components/WorkProcess'
import { fetchSolutions, Solution } from './../services/strapiService'
import { SolutionsBannerDetails } from '../components/SolutionsBannerDetails'
import Clients from '../components/Clients'
import { useLocale } from '../contexts'

const SolutionDetailsPage: React.FC = () => {
  const { type } = useParams<{ type: string }>()
  const [solution, setSolution] = useState<Solution>({} as Solution)
  const { locale } = useLocale()

  const getSolution = async () => {
    try {
      const {
        data: { data },
      } = await fetchSolutions(locale)

      const foundSolution = data?.find((solution) => solution.type === type)
      if (foundSolution) setSolution(foundSolution)
    } catch (error) {
      console.error('Erro ao buscar dados de soluções:', error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getSolution()
  }, [type, locale])

  if (!solution) {
    return <p>Carregando solução...</p>
  }

  return (
    <SolutionDetailsWrapper>
      <SolutionsBannerDetails solution={solution} />
      <WorkProcess />
      <Clients />
      <FAQData />
    </SolutionDetailsWrapper>
  )
}

export default SolutionDetailsPage

const SolutionDetailsWrapper = styled.div`
  padding: 0;
  margin: 0;
`
