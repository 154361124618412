import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import {
  imageUrl,
  TestimonialTranslatorTexts,
  fetchTestimonialTranslatorTexts,
} from '../../services/strapiService'
import { useLocale } from '../../contexts'

const TestimonialTranslator: React.FC = () => {
  const [testimonialTranslatorTexts, setTestimonialTranslatorTexts] =
    useState<TestimonialTranslatorTexts>({} as TestimonialTranslatorTexts)

  const { locale } = useLocale()

  const getTestimonialTranslatorTexts = async () => {
    try {
      const { data } = await fetchTestimonialTranslatorTexts(locale)

      setTestimonialTranslatorTexts(data?.data)
    } catch (error) {
      console.error('Erro ao buscar dados do banner do tradutor:', error)
    }
  }

  useEffect(() => {
    getTestimonialTranslatorTexts()
  }, [locale])

  return (
    <Wrapper>
      <LeftPanel>
        <h1>{testimonialTranslatorTexts?.title}</h1>
        <p>{testimonialTranslatorTexts?.subtitle}</p>
      </LeftPanel>
      <TestimonialPanel>
        {testimonialTranslatorTexts?.list?.map((item) => (
          <TestimonialCard key={item?.name}>
            <Avatar src={imageUrl(item?.image?.url)} alt={item?.name} />

            <TestimonialContent>
              <p>{item?.description}</p>
              <span>{item?.name}</span>
            </TestimonialContent>
          </TestimonialCard>
        ))}
      </TestimonialPanel>
    </Wrapper>
  )
}

export default TestimonialTranslator

// Styled Components
const Wrapper = styled.div`
  display: flex;
  background-color: var(--blue);
  padding: 60px 80px;
  color: white;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`

const LeftPanel = styled.div`
  flex: 1;
  padding-right: 40px;

  h1 {
    font-family: 'Inter', sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -1.2000000476837158px;
    text-align: left;

    margin-bottom: 20px;
  }

  p {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
  }

  @media (max-width: 1024px) {
    padding-right: 0;
    margin-bottom: 40px;
  }
`

const TestimonialPanel = styled.div`
  flex: 2;
  display: flex;
  overflow: scroll;
  gap: 20px;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--hard-blue);
    border-radius: 10px;
    opacity: 1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #02277e;
  }
`

const TestimonialCard = styled.div`
  background: var(--white-transparent);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
`

const Avatar = styled.img`
  width: 104px;
  height: 104px;
  object-fit: cover;
`

const TestimonialContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;

  p {
    font-family: 'Inter', sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: white;

    margin-bottom: 10px;
  }

  span {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.4000000059604645px;

    color: var(--grey);
  }
`
