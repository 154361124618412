import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { IndustriesBannerDetails } from '../components/IndustriesBannerDetails'
import WorkProcess from '../components/WorkProcess'
import Clients from '../components/Clients'
import FAQData from '../components/FAQData'

const IndustrieDetailsPage: React.FC = () => {
  const { type } = useParams<{ type: string }>() // Parâmetro "id" da URL

  if (!type) {
    return <LoadingMessage>Carregando dados da indústria...</LoadingMessage>
  }

  return (
    <IndustryDetailsWrapper>
      <IndustriesBannerDetails industryType={type} />
      <WorkProcess />
      <Clients />
      <FAQData />
    </IndustryDetailsWrapper>
  )
}

export default IndustrieDetailsPage

const LoadingMessage = styled.p`
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
`

const IndustryDetailsWrapper = styled.div`
  padding: 0;
  margin: 0;
`
