import React from 'react'
import styled from 'styled-components'

import { CiStar } from 'react-icons/ci'
import { PiArrowsOutSimpleLight } from 'react-icons/pi'

import { DefaultFields } from '../../services/strapiService'

interface ValuesProps extends Pick<DefaultFields, 'title'> {
  list: {
    description: string
    iconName: string
  }[]
}

const iconElement: Record<string, React.JSX.Element> = {
  'double-arrow': <PiArrowsOutSimpleLight size={60} color='var(--green)' />,
  star: <CiStar size={60} color='var(--green)' />,
}

const Values = ({ values }: { values: ValuesProps }) => {
  return (
    <Container>
      <LeftSide>
        <Title>{values?.title}</Title>
      </LeftSide>
      <RightSide>
        {values?.list?.map((item) => (
          <Card key={item?.description}>
            <IconWrapper>{iconElement[item?.iconName]}</IconWrapper>
            <Description>{item?.description}</Description>
          </Card>
        ))}
      </RightSide>
    </Container>
  )
}

export default Values

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--hard-blue);
  padding: 80px 40px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`

const LeftSide = styled.div`
  width: 100%;
  max-width: 300px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }
`

const Title = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 43.32px;
  letter-spacing: -1.2000000476837158px;
  text-align: left;
  color: white;

  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
`

const RightSide = styled.div`
  display: flex;
  flex: 1;
  gap: 30px;
  justify-content: space-between;
  max-width: 1000px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 20px;
  }
`

const Card = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  gap: 40px;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  @media (max-width: 768px) {
    min-width: 240px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`

const Description = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 37.44px;
  text-align: left;

  color: white;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`
