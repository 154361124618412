import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { PiTranslate } from 'react-icons/pi'
import { GoArrowUpRight } from 'react-icons/go'
import { IoIosGitCommit } from 'react-icons/io'

import Modal from '../Modal'
import {
  imageUrl,
  MainHomeBanner,
  fetchHomeBannerTexts,
} from '../../services/strapiService'
import { useLocale } from '../../contexts'
import SwitchAnimated from '../SwitchAnimated/SwitchAnimated'

const Banner: React.FC = () => {
  const [homeBannerTexts, setHomeBannerTexts] = useState<MainHomeBanner>(
    {} as MainHomeBanner
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const { locale } = useLocale()

  const handleRequestQuoteButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleTranslatorButtonClick = () => {
    navigate('/tradutores')
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const getHomeBannerTexts = async () => {
    try {
      const { data } = await fetchHomeBannerTexts(locale)

      setHomeBannerTexts(data?.data)
    } catch (error) {
      console.error('Erro ao carregar textos do banner da home.', error)
    }
  }

  useEffect(() => {
    getHomeBannerTexts()
  }, [locale])

  return (
    <BannerWrapper>
      <BannerContent>
        <CultureText>
          <IoIosGitCommit />
          {homeBannerTexts?.badge}
        </CultureText>
        <TitleContent>
          <HeroTitle>
            {homeBannerTexts?.title
              ?.split(' ')
              ?.slice(0, homeBannerTexts?.title?.split(' ')?.length - 2)
              .join(' ')}
          </HeroTitle>
          <HeroTitle>
            {homeBannerTexts?.title
              ?.split(' ')
              ?.slice(homeBannerTexts?.title?.split(' ')?.length - 2)
              ?.map((text, index) => (
                <React.Fragment key={text}>
                  {text} <br />
                  {index ===
                    homeBannerTexts?.title?.split(' ')?.length -
                      homeBannerTexts?.title?.split(' ')?.length && (
                    <SwitchAnimated isHome />
                  )}
                </React.Fragment>
              ))}
          </HeroTitle>
        </TitleContent>

        <Subtitle>{homeBannerTexts?.subtitle}</Subtitle>
        <ButtonGroup>
          <Button primary onClick={handleRequestQuoteButtonClick}>
            {homeBannerTexts?.buttonLabelRequestQuote}
            <GoArrowUpRight />
          </Button>
          <Button onClick={handleTranslatorButtonClick}>
            {homeBannerTexts?.buttonLabelBeTranslator} <PiTranslate />
          </Button>
        </ButtonGroup>
      </BannerContent>
      <BannerImage
        src={imageUrl(homeBannerTexts?.image?.url)}
        alt='Banner Image'
      />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
    </BannerWrapper>
  )
}

export default Banner

const TitleContent = styled.div`
  gap: 10px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 1rem;
  }
`
const BannerWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 120px 90px;
  background-color: var(--hard-blue);
  color: white;
  position: relative;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    height: auto;
    padding: 1rem 1rem 0 1rem;
  }
`

const BannerContent = styled.div`
  max-width: 600px;
  z-index: 1;
  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
    align-items: center;
  }
`

const CultureText = styled.p`
  color: var(--white);
  font-family: 'Inter', sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 23.94px;
  text-align: left;
  margin-bottom: 30px;

  display: flex;
  gap: 10px;
  svg {
    font-size: 21px;
  }
  @media (max-width: 768px) {
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

const HeroTitle = styled.h1`
  font-size: 52.66px;
  font-weight: 400;
  line-height: 60.03px;
  text-align: left;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  gap: 10px;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 30.03px;
  }
`

const Subtitle = styled.p`
  margin-bottom: 2rem;
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 0.9rem;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 768px) {
    align-items: center;
    gap: 0.5rem;
  }
`

const Button = styled.button<{ primary?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 70%;
  gap: 20px;
  padding: 15px 28px;
  font-size: 1rem;
  border-radius: 2px;
  cursor: pointer;

  background-color: ${(props) => (props.primary ? 'white' : 'transparent')};
  color: ${(props) => (props.primary ? 'hard-blue' : 'white')};
  border: ${(props) => (props.primary ? '1px solid white' : 'none')};

  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.primary ? 'transparent' : 'blue')};
    color: white;
    border: ${(props) => (props.primary ? '1px solid white' : 'none')};
    backdrop-filter: blur(2px);
  }
`

const BannerImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 80vh;
  z-index: 0;
  @media (max-width: 768px) {
    position: relative;
    height: auto;
    width: 100%;
  }
`
