import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  position: relative;

  .element {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 61px;
    width: 61px;
    border-radius: 20px;
  }
`;

export const Label = styled.label`
  font-size: 15px;
  line-height: 1.2;
  color: #000;
  position: absolute;
  font-weight: 400;
  top: -25px;
  left: 0px;
  transition: all 0.2s ease;
  z-index: 2;
`;

type InputProps = {
  errorInput?: boolean;
};

export const InputBase = styled.input<InputProps>`
  position: relative;
  width: 100%;
  height: 60px !important;
  background-color: #fff;
  padding: 12px;
  padding-left: 50px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #4D4D4D;
  border: ${({ errorInput }: InputProps) =>
    errorInput ? '2px solid #F66767' : '1px solid #A9A9A9'};

  &::placeholder {
    color: #A9A9A9;
    font-size: 14px;
  }

  &:focus {
    border: 2px solid var(--hard-blue);
    outline: none;
  }
`;

export const ErrorMessage = styled.p`
  width: 100%;
  text-align: left;
  font-size: 12px !important;
  color: #F66767 !important;
  margin-top: 10px;
`;
