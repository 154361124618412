import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { GoArrowRight } from 'react-icons/go'
import Select, {
  OptionProps,
  SingleValue,
  SingleValueProps,
} from 'react-select'

import * as S from './styles'
import Input from '../Input'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { RiUserLine } from 'react-icons/ri'
import { IoPhonePortraitOutline } from 'react-icons/io5'
import { MdMailOutline } from 'react-icons/md'
import { optionsLanguages, optionsSpecialty } from '../SignupTranslator/consts'
import { PiTranslateDuotone } from 'react-icons/pi'
import { FiEdit3, FiUpload } from 'react-icons/fi'
import { BsCalendarDate } from 'react-icons/bs'
import SwitchAnimated from '../SwitchAnimated/SwitchAnimated'
import {
  fetchRegisterTranslatorModalForm,
  postRequestQuote,
  RegisterTranslatorModalForm,
} from '../../services/strapiService'
import { useLocale } from '../../contexts'

type Values = {
  value: string
  label: string
  flag?: any
}

type SelectValuesType = SingleValue<Values>

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  serviceType?: SelectValuesType
}

const schema = z.object({
  nome: z.string().min(1, 'Campo obrigatório'),
  email: z.string().email('E-mail inválido'),
  telefone: z
    .string()
    .min(1, { message: 'Número obrigatório' })
    .min(14, 'Número inválido'),
})

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, serviceType }) => {
  const [step, setStep] = useState(3)
  const [modalFormTexts, setModalFormTexts] =
    useState<RegisterTranslatorModalForm>({} as RegisterTranslatorModalForm)
  const [formData, setFormData] = useState({
    projetoTipo: serviceType,
    origem: {} as SelectValuesType,
    destino: {} as SelectValuesType,
    detalhesProjeto: '',
    dataEntrega: '',
    arquivo: null as File | null,
  })
  const refFileInput = useRef<HTMLInputElement>(null)

  const { locale } = useLocale()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.TypeOf<typeof schema>>({
    resolver: zodResolver(schema),
  })

  const fetchModalFormTexts = async () => {
    try {
      const { data } = await fetchRegisterTranslatorModalForm(locale)
      setModalFormTexts(data?.data)
    } catch (error) {}
  }

  useEffect(() => {
    fetchModalFormTexts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, projetoTipo: serviceType }))
  }, [serviceType])

  const handleNextStep = () => {
    if (step < 2) {
      setStep(step + 1)
    } else {
      handleSubmit(handleRequestQuote)()
    }
  }

  const handleRequestQuote = async (data: z.TypeOf<typeof schema>) => {
    if (!formData.arquivo) return alert('Campo de Arquivo é obrigatório!')

    const projectType = formData.projetoTipo?.value
    if (!projectType) return alert('Campo de Tipo de Projeto obrigatório!')

    const origin = formData.origem?.value
    if (!origin) return alert('Campo de Idiomas obrigatório!')

    const destiny = formData.destino?.value
    if (!destiny) return alert('Campo de Idiomas obrigatório!')

    try {
      await postRequestQuote({
        ...data,
        ...formData,
        projetoTipo: projectType,
        origem: origin,
        destino: destiny,
      })
      setStep(step + 1)
    } catch (error) {
      alert('Ocorreu um erro ao cadastrar suas informações.')
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    setFormData({ ...formData, arquivo: file })
  }

  const SingleValue = (
    props: SingleValueProps<{
      value: string
      label: string
      flag?: any
    }>
  ) => (
    <S.SingleValueLabelContainer>
      {props.data?.flag ? (
        <img
          src={props.data?.flag}
          alt={`Bandeira ${props?.data?.flag}`}
          width={20}
          height={20}
        />
      ) : (
        <PiTranslateDuotone width={25} height={25} color='#A9A9A9' />
      )}
      {props?.data?.label}
    </S.SingleValueLabelContainer>
  )

  const Option = (
    props: OptionProps<{
      value: string
      label: string
      flag?: any
    }>
  ) => (
    <S.OptionContainer
      ref={props.innerRef}
      {...props.innerProps}
      isFocused={props?.isFocused}
    >
      {props.data?.flag ? (
        <img
          src={props.data?.flag}
          alt={`Bandeira ${props?.data?.flag}`}
          width={20}
          height={20}
        />
      ) : (
        <PiTranslateDuotone width={25} height={25} color='#A9A9A9' />
      )}
      {props?.data?.label}
    </S.OptionContainer>
  )

  if (!isOpen) return null

  return (
    <S.ModalOverlay
      className='modal-container'
      onClick={(e) => {
        //@ts-ignore
        const classNameModal = e.nativeEvent.target.className
        classNameModal?.split(' ').includes('modal-container') && onClose()
      }}
    >
      <S.ModalContent>
        <S.CloseButton
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onClose()
            setStep(1)
          }}
        >
          <AiOutlineClose />
        </S.CloseButton>
        <h2>{modalFormTexts?.data?.title}</h2>
        <S.StepProgress>
          <S.StepCircleItem active={step >= 1}>1</S.StepCircleItem>
          <S.StepItem active={step >= 1} />
          <S.StepCircleItem active={step >= 2}>2</S.StepCircleItem>
          <S.StepItem active={step >= 2} />
          <S.StepCircleItem active={step === 3}>3</S.StepCircleItem>
        </S.StepProgress>

        {/* Formulário */}
        {step === 1 && (
          <S.StepWrapper>
            <S.InputWrapper>
              <Input
                type='text'
                register={register('nome')}
                errorMessage={errors.nome?.message}
                icon={<RiUserLine color='#A9A9A9' />}
                label={modalFormTexts?.data?.step1?.nameLabel}
                placeholder={modalFormTexts?.data?.step1?.namePlaceholder}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <Input
                type='text'
                register={register('email')}
                errorMessage={errors.email?.message}
                icon={<MdMailOutline color='#A9A9A9' />}
                label={modalFormTexts?.data?.step1?.emailLabel}
                placeholder={modalFormTexts?.data?.step1?.emailPlaceholder}
              />
            </S.InputWrapper>
            <S.InputWrapper>
              <Input
                type='text'
                mask='(99) 99999-9999'
                register={register('telefone')}
                errorMessage={errors.telefone?.message}
                label={modalFormTexts?.data?.step1?.telefoneLabel}
                icon={<IoPhonePortraitOutline color='#A9A9A9' />}
                placeholder={modalFormTexts?.data?.step1?.telefonePlaceholder}
              />
            </S.InputWrapper>

            <S.InputWrapper>
              <label className='label-text'>
                {modalFormTexts?.data?.step1?.projectTypeLabel}
              </label>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: 0,
                    ':hover': {
                      border: '2px solid var(--hard-blue)',
                    },
                    border: state.isFocused
                      ? '2px solid var(--hard-blue)'
                      : '1px solid #A9A9A9',
                    maxHeight: '59px',
                  }),
                  container: (base) => ({
                    ...base,
                    zIndex: 100,
                    height: '59px',
                    maxHeight: '59px',
                    borderRadius: 0,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    alignItems: 'center',
                    height: '59px',
                    maxHeight: '59px',
                    borderRadius: 0,
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    maxHeight: '55px',
                    display: 'flex',
                    zIndex: 10,
                  }),
                }}
                isMulti={false}
                isSearchable={false}
                options={optionsSpecialty}
                components={{ SingleValue, Option }}
                onChange={(e) => setFormData({ ...formData, projetoTipo: e })}
                placeholder={
                  modalFormTexts?.data?.step1?.projectTypePlaceholder
                }
              />
            </S.InputWrapper>

            <S.NextButton onClick={handleSubmit(handleNextStep)}>
              {modalFormTexts?.data?.nextButtonLabel} <GoArrowRight />
            </S.NextButton>
          </S.StepWrapper>
        )}

        {step === 2 && (
          <S.StepWrapper>
            <S.SelectLanguagesWrapper>
              <S.InputWrapper>
                <label className='label-text'>
                  {modalFormTexts?.data?.step2?.originLanguageLabel}
                </label>
                <Select
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderRadius: 0,
                      ':hover': {
                        border: '2px solid var(--hard-blue)',
                      },
                      border: state.isFocused
                        ? '2px solid var(--hard-blue)'
                        : '1px solid #A9A9A9',
                      maxHeight: '59px',
                    }),
                    container: (base) => ({
                      ...base,
                      zIndex: 200,
                      padding: '2px',
                      height: '59px',
                      maxHeight: '59px',
                      borderRadius: 0,
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      alignItems: 'center',
                      height: '59px',
                      maxHeight: '59px',
                      borderRadius: 0,
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      display: 'flex',
                      maxHeight: '55px',
                      zIndex: 100,
                    }),
                  }}
                  isMulti={false}
                  isSearchable={false}
                  options={optionsLanguages}
                  components={{ SingleValue, Option }}
                  onChange={(e) => setFormData({ ...formData, origem: e })}
                  placeholder={
                    modalFormTexts?.data?.step2?.originLanguagePlaceholder
                  }
                />
              </S.InputWrapper>

              <S.InputWrapper>
                <label className='label-text'>
                  {modalFormTexts?.data?.step2?.destinyLanguageLabel}
                </label>
                <Select
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderRadius: 0,
                      ':hover': {
                        border: '2px solid var(--hard-blue)',
                      },
                      border: state.isFocused
                        ? '2px solid var(--hard-blue)'
                        : '1px solid #A9A9A9',
                      maxHeight: '59px',
                    }),
                    container: (base) => ({
                      ...base,
                      zIndex: 200,
                      padding: '2px',
                      height: '59px',
                      maxHeight: '59px',
                      borderRadius: 0,
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      alignItems: 'center',
                      height: '59px',
                      maxHeight: '59px',
                      borderRadius: 0,
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      display: 'flex',
                      maxHeight: '55px',
                      zIndex: 100,
                    }),
                  }}
                  isMulti={false}
                  isSearchable={false}
                  options={optionsLanguages}
                  components={{ SingleValue, Option }}
                  onChange={(e) => setFormData({ ...formData, destino: e })}
                  placeholder={
                    modalFormTexts?.data?.step2?.destinyLanguagePlaceholder
                  }
                />
              </S.InputWrapper>
            </S.SelectLanguagesWrapper>

            <S.InputWrapper>
              <S.InputLabelFile>
                {modalFormTexts?.data?.step2?.detailsLabel}
              </S.InputLabelFile>
              <S.InputWrapperText>
                <FiEdit3 color='#A9A9A9' />
                <textarea
                  value={formData.detalhesProjeto}
                  placeholder={modalFormTexts?.data?.step2?.detailsPlaceholder}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      detalhesProjeto: e.target.value,
                    })
                  }
                />
              </S.InputWrapperText>
            </S.InputWrapper>

            <S.InputWrapperTextDate>
              <S.InputLabelTextDate>
                {modalFormTexts?.data?.step2?.dateLabel}
              </S.InputLabelTextDate>
              <S.InputDate>
                <BsCalendarDate />
                <input
                  type='date'
                  value={formData.dataEntrega}
                  onChange={(e) =>
                    setFormData({ ...formData, dataEntrega: e.target.value })
                  }
                />
              </S.InputDate>
            </S.InputWrapperTextDate>

            <S.InputWrapper>
              <S.InputLabelTextDate className='label-file'>
                {modalFormTexts?.data?.step2?.fileLabel}
              </S.InputLabelTextDate>
              <S.FileInputWrapper
                type='button'
                onClick={() => refFileInput?.current?.click()}
              >
                <FiUpload size={16} color='#4D4D4D' />
                <S.PlaceholderFileInput>
                  {formData?.arquivo?.name ||
                    modalFormTexts?.data?.step2?.filePlaceholder}
                </S.PlaceholderFileInput>
                <S.FileInput
                  type='file'
                  name='files'
                  ref={refFileInput}
                  accept='.pdf, .doc, .docx'
                  onChange={handleFileChange}
                />
              </S.FileInputWrapper>
            </S.InputWrapper>
            <S.NextButton onClick={handleNextStep}>Próximo passo</S.NextButton>
          </S.StepWrapper>
        )}

        {step === 3 && (
          <S.StepWrapper>
            <S.SuccessIcon>
              <img
                src={require('../../assets/success.png')}
                alt='Ícone de sucesso'
              />
            </S.SuccessIcon>

            <S.SuccessText>
              <h2 className='success-text'>
                {modalFormTexts?.data?.step3?.successMessage
                  ?.split(' ')
                  ?.slice(
                    0,
                    modalFormTexts?.data?.step3?.successMessage?.split(' ')
                      ?.length - 2
                  )
                  .join(' ')}
                {modalFormTexts?.data?.step3?.successMessage
                  ?.split(' ')
                  ?.slice(
                    modalFormTexts?.data?.step3?.successMessage?.split(' ')
                      ?.length - 2
                  )
                  ?.map((text, index) => (
                    <React.Fragment key={text}>
                      <div className='success-container'>
                        {text}
                        {index ===
                          modalFormTexts?.data?.step3?.successMessage?.split(
                            ' '
                          )?.length -
                            modalFormTexts?.data?.step3?.successMessage?.split(
                              ' '
                            )?.length && <SwitchAnimated />}
                      </div>
                    </React.Fragment>
                  ))}
              </h2>
            </S.SuccessText>
          </S.StepWrapper>
        )}
      </S.ModalContent>
    </S.ModalOverlay>
  )
}

export default Modal
