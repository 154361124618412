import React from 'react';
import SolutionsBox from '../components/SolutionsBox';
import IndustriesBox from '../components/IndustriesBox';
import Clients from '../components/Clients';

const Solutions: React.FC = () => {
  return (
    <>
      <SolutionsBox isGrid />
      <IndustriesBox />
      <Clients />

    </>
  );
};

export default Solutions;
