import React from 'react'

import IndustriesBox from '../components/IndustriesBox'
import Clients from '../components/Clients'
const Industries: React.FC = () => {
  return (
    <>
      <IndustriesBox />
      <Clients />
    </>
  )
}

export default Industries
