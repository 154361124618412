// src/pages/styles.ts
import styled from 'styled-components';

export const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeroSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: var(--hard-blue);
  color: white;
`;

export const HeroContent = styled.div`
  max-width: 600px;
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }
`;

export const HeroImage = styled.img`
  max-width: 600px;
  height: auto;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Button = styled.button<{ primary?: boolean }>`
  background-color: ${({ primary }) => (primary ? 'var(--blue)' : 'transparent')};
  color: ${({ primary }) => (primary ? 'white' : 'var(--blue)')};
  border: ${({ primary }) => (primary ? 'none' : '2px solid var(--blue)')};
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ primary }) => (primary ? 'var(--soft-blue)' : '#e0f7ff')};
  }
`;

export const WorkProcessSection = styled.section`
  padding: 4rem 2rem;
  background-color: var(--grey);
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--hard-blue);
  }
`;

export const ProcessSteps = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

export const StepDescription = styled.p`
  font-size: 1.25rem;
  color: #666;
`;

export const ClientsSection = styled.section`
  padding: 4rem 2rem;
  background-color: white;
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--hard-blue);
  }
`;

export const ClientsLogos = styled.div`
  display: flex;
  justify-content: space-around;

  img {
    max-width: 150px;
    height: auto;
  }
`;

export const FAQSection = styled.section`
  padding: 4rem 2rem;
  background-color: var(--grey);
  text-align: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--hard-blue);
  }
`;

export const FAQList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const FAQItem = styled.li`
  margin-bottom: 1.5rem;
`;

export const Question = styled.h3`
  font-size: 1.25rem;
  color: var(--hard-blue);
`;

export const Answer = styled.p`
  font-size: 1rem;
  color: #666;
`;
