import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { TfiTarget } from 'react-icons/tfi'
import { BiBuildings } from 'react-icons/bi'
import { CiMedicalCross } from 'react-icons/ci'
import { IoTrainOutline } from 'react-icons/io5'
import { BsMinecartLoaded } from 'react-icons/bs'
import { HiOutlineCheckBadge } from 'react-icons/hi2'
import { RiMedicineBottleLine } from 'react-icons/ri'
import { GoArrowUpRight, GoZap } from 'react-icons/go'
import { TbReceipt2, TbTargetArrow } from 'react-icons/tb'
import {
  AiOutlineCar,
  AiOutlineGlobal,
  AiOutlineArrowRight,
} from 'react-icons/ai'
import {
  PiWaves,
  PiTranslate,
  PiCertificate,
  PiAirplaneTilt,
  PiBuildingApartment,
} from 'react-icons/pi'

import {
  fetchIndustries,
  fetchIndustrySectionTexts,
  Industry,
  IndustrySectionTexts,
} from '../../services/strapiService'
import Modal from '../Modal'
import { useLocale } from '../../contexts'

// Mapeamento dos ícones
const iconMapping = {
  AiOutlineCar: <AiOutlineCar />,
  PiAirplaneTilt: <PiAirplaneTilt />,
  TbReceipt2: <TbReceipt2 />,
  GoZap: <GoZap />,
  BiBuildings: <BiBuildings />,
  RiMedicineBottleLine: <RiMedicineBottleLine />,
  IoTrainOutline: <IoTrainOutline />,
  TfiTarget: <TfiTarget />,
  PiCertificate: <PiCertificate />,
  CiMedicalCross: <CiMedicalCross />,
  BsMinecartLoaded: <BsMinecartLoaded />,
  PiWaves: <PiWaves />,
  TbTargetArrow: <TbTargetArrow />,
  HiOutlineCheckBadge: <HiOutlineCheckBadge />,
  PiBuildingApartment: <PiBuildingApartment />,
}

const IndustriesBox: React.FC = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [industriesData, setIndustriesData] = useState<Industry[]>([])
  const [industriesSectionTexts, setIndustriesSectionTexts] =
    useState<IndustrySectionTexts>({} as IndustrySectionTexts)
  const { locale } = useLocale()

  // Fetch industries data from Strapi
  const getIndustries = useCallback(async () => {
    try {
      const {
        data: { data: industries },
      } = await fetchIndustries(locale)

      const formattedIndustries = industries.map((industry) => {
        const iconName = industry.iconName?.trim() || ''
        return {
          ...industry,
          icon:
            iconName && iconMapping[iconName as keyof typeof iconMapping] ? (
              iconMapping[iconName as keyof typeof iconMapping]
            ) : (
              <AiOutlineGlobal />
            ), // Ícone padrão
        }
      })
      setIndustriesData(formattedIndustries)
    } catch (error) {
      console.error('Erro ao buscar dados das indústrias:', error)
    }
  }, [locale])

  const getIndustrySectionText = useCallback(async () => {
    try {
      const { data } = await fetchIndustrySectionTexts(locale)

      setIndustriesSectionTexts(data?.data)
    } catch (error) {
      console.error('Erro ao buscar dados das indústrias:', error)
    }
  }, [locale])

  useEffect(() => {
    getIndustries()
    getIndustrySectionText()
  }, [locale])

  const handleTranslatorButtonClick = () => {
    navigate('/tradutores')
  }

  const handleRequestQuoteButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleIndustryClick = (type: string) => {
    navigate(`/industrias/${type}`)
  }

  function handleCloseModal(): void {
    throw new Error('Function not implemented.')
  }

  return (
    <IndustriesWrapper>
      <LeftContent>
        <StickyWrapper>
          <h2>
            {industriesSectionTexts?.title_part_1}
            <br />
            {industriesSectionTexts?.title_part_2}{' '}
            <PiBuildingApartment style={{ marginBottom: -4 }} />{' '}
            {industriesSectionTexts?.title_part_3}
          </h2>

          <p>{industriesSectionTexts?.subtitle}</p>
          <ButtonGroup>
            <Button primary onClick={handleRequestQuoteButtonClick}>
              {industriesSectionTexts?.buttonLabelRequestQuote}
              <GoArrowUpRight />
            </Button>
            <Button onClick={handleTranslatorButtonClick}>
              {industriesSectionTexts?.buttonLabelBeTranslator}
              <PiTranslate />
            </Button>
          </ButtonGroup>
          <BuildingImage
            src='/homepage/predio.png'
            alt='Prédio Representativo'
          />
        </StickyWrapper>
      </LeftContent>
      <RightContent>
        <IndustriesGrid>
          {industriesData.map((industry) => (
            <IndustryCard
              key={industry.id}
              onClick={() => handleIndustryClick(industry.type)}
            >
              <ArrowLink>
                <AiOutlineArrowRight />
              </ArrowLink>
              <InfoLine>
                <IndustryIcon>{industry.icon}</IndustryIcon>
                <IndustryTitle>{industry.title}</IndustryTitle>
              </InfoLine>
            </IndustryCard>
          ))}
        </IndustriesGrid>
      </RightContent>
      {isModalOpen && <Modal isOpen={isModalOpen} onClose={handleCloseModal} />}
    </IndustriesWrapper>
  )
}

export default IndustriesBox

const IndustriesWrapper = styled.div`
  display: flex;
  padding: 120px 90px 0;
  justify-content: space-between;
  background-color: var(--hard-blue);
  color: white;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`

const LeftContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding-right: 20px;
  position: relative;

  @media (max-width: 1024px) {
    padding-right: 0;
  }
`

const StickyWrapper = styled.div`
  position: sticky;
  top: 40px;

  height: 120vh;
  text-align: left;

  h2 {
    font-size: 42px;
    line-height: 1.2;
    font-weight: 400;
    margin-bottom: 20px;

    span {
      color: var(--green);
      font-weight: bold;
    }
  }

  p {
    font-size: 21px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  @media (max-width: 1024px) {
    height: auto;
    width: 100%;
    max-width: 100%;

    p {
      font-size: 18px;
    }

    h2 {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 768px) {
    gap: 0.5rem;
    display: none;
  }
`

export const Button = styled.button<{ primary?: boolean }>`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${(props) => (props.primary ? 'white' : 'transparent')};
  color: ${(props) => (props.primary ? 'hard-blue' : 'white')};
  border: ${(props) => (props.primary ? '1px solid white' : 'none')};
  padding: 15px 28px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  justify-content: center;
  align-items: center;
  z-index: 200;

  &:hover {
    background-color: ${(props) => (props.primary ? 'transparent' : 'blue')};
    color: ${(props) => (props.primary ? 'white' : 'white')};
    border: ${(props) => (props.primary ? '1px solid white' : 'none')};
    backdrop-filter: blur(2px);
  }
`

const BuildingImage = styled.img`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 800px;
  object-fit: cover;
  opacity: 0.8;

  @media (max-width: 1100px) {
    height: 600px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

const RightContent = styled.div`
  flex: 2;
  padding-left: 20px;

  @media (max-width: 1024px) {
    padding-left: 0;
    max-height: auto;
  }
`

const IndustriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-bottom: 90px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`

const InfoLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const IndustryCard = styled.div`
  background: var(--blue);
  padding: 20px;
  min-height: 148px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: background 0.3s ease;
  align-items: flex-end;
  justify-content: space-between;

  &:hover {
    background: var(--green);
  }
`

const IndustryIcon = styled.div`
  font-size: 30px;
`

const IndustryTitle = styled.h4`
  font-size: 18px;
  margin: 0;
  flex: 1;
  padding: 0 10px;
`

const ArrowLink = styled.a`
  font-size: 20px;
  color: inherit;
`
