import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import {
  ClientsLogosCarousel,
  fetchClientsCarousel,
} from '../../services/strapiService'
import { useLocale } from '../../contexts'

const Clients: React.FC = () => {
  const [clients, setClients] = React.useState<ClientsLogosCarousel>(
    {} as ClientsLogosCarousel
  )

  const { locale } = useLocale()

  const getClientsCarousel = async () => {
    try {
      const { data } = await fetchClientsCarousel(locale)
      setClients(data?.data)
    } catch (error) {
      console.error('Erro ao buscar dados dos clientes:', error)
    }
  }

  useEffect(() => {
    getClientsCarousel()
  }, [locale])

  return (
    <ClientsSection>
      <h2>{clients?.title}</h2>
      <LogosWrapper>
        <SlideTracker>
          {clients?.list?.map((client, index) => (
            <SlideClientsContainer key={index}>
              <Logo
                src={
                  client?.url
                    ? `https://engaging-chickens-0267a7699e.media.strapiapp.com/${client?.url}`
                    : '/path/to/default-image.png'
                }
                alt={'Clientes'}
              />
            </SlideClientsContainer>
          ))}
          {clients?.list?.map((client, index) => (
            <SlideClientsContainer key={index}>
              <Logo
                src={
                  client?.url
                    ? `https://engaging-chickens-0267a7699e.media.strapiapp.com/${client?.url}`
                    : '/path/to/default-image.png'
                }
                alt={'Clientes'}
              />
            </SlideClientsContainer>
          ))}
        </SlideTracker>
      </LogosWrapper>
    </ClientsSection>
  )
}

export default Clients

// Styled Components

const ClientsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 60px;
  background-color: white;
  text-align: center;

  h2 {
    display: flex;
    font-size: 32px;
    font-weight: 400;
    color: var(--grey-black);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 60px 30px;
    gap: 40px;
    h2 {
      font-size: 28px;
    }
  }
`

const scroll = keyframes`
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(-100%);
  }
`

const SlideClientsContainer = styled.div`
  display: flex;
  height: 100px;
  width: 200px;
`

const SlideTracker = styled.div`
  display: flex;
  animation: ${scroll} 30s linear infinite;

  width: calc(100px * 20);
`

const LogosWrapper = styled.div`
  @mixin white-gradient {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  background: white;
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;

  &::before,
  &::after {
    @include white-gradient;
    content: '';
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

const Logo = styled.img`
  width: 120px;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  object-fit: contain;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 80px;
    margin: 0px 20px;
  }
`
