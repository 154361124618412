import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { BsLayers } from 'react-icons/bs'
import { FiUserCheck } from 'react-icons/fi'
import { TbUserCheck } from 'react-icons/tb'
import { RiVerifiedBadgeLine } from 'react-icons/ri'

import {
  WhyTranslatorTexts,
  fetchWhyTranslatorText,
} from '../../services/strapiService'
import { useLocale } from '../../contexts'

const iconsComponent: Record<string, React.JSX.Element> = {
  'people-check-up': <FiUserCheck size={40} />,
  'bordered-check': <RiVerifiedBadgeLine size={40} />,
  'people-check-down': <TbUserCheck size={40} />,
  'two-papers': <BsLayers size={40} />,
}

const WhyTranslator: React.FC = () => {
  const [whyTranslatorTexts, setWhyTranslatorTexts] =
    useState<WhyTranslatorTexts>({} as WhyTranslatorTexts)

  const { locale } = useLocale()

  const getWhyTranslatorText = async () => {
    try {
      const { data } = await fetchWhyTranslatorText(locale)

      setWhyTranslatorTexts(data?.data)
    } catch (error) {
      console.error('Erro ao buscar dados do banner do tradutor:', error)
    }
  }

  useEffect(() => {
    getWhyTranslatorText()
  }, [locale])

  return (
    <Wrapper>
      <TitleSection>
        <h2>{whyTranslatorTexts?.title}</h2>
        <p>{whyTranslatorTexts?.subtitle}</p>
      </TitleSection>
      <CardsContainer>
        {whyTranslatorTexts?.list?.map((item, index) => (
          <Card key={index}>
            <IconWrapper>{iconsComponent[item?.iconName]}</IconWrapper>
            <CardTitle>{item?.title}</CardTitle>
            <CardDescription>{item?.subtitle}</CardDescription>
          </Card>
        ))}
      </CardsContainer>
    </Wrapper>
  )
}

export default WhyTranslator

// Styled Components
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 80px;
  background-color: var(--hard-blue);

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`

const TitleSection = styled.div`
  text-align: left;
  margin-bottom: 80px;

  h2 {
    font-size: 36px;
    color: white;
  }

  p {
    font-size: 16px;
    color: #a7c2ef;
    max-width: 700px;
    line-height: 1.8;
  }
`

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`

const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 12px;
  padding: 20px;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }
`

const IconWrapper = styled.div`
  background: var(--green);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px;

  svg {
    color: var(--hard-blue);
  }
`

const CardTitle = styled.h4`
  font-size: 18px;
  color: white;
  margin-bottom: 15px;
`

const CardDescription = styled.p`
  font-size: 14px;
  color: #d1e0fc;
  line-height: 1.6;
`
