import React, { useEffect, useState } from 'react';
import * as S from './styles';

const words = ["言語", "Idioma", "Language", "Langue", "Sprache", "Lingua"];

const RotatingText: React.FC = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <S.RotatingWord>{words[currentWordIndex]}</S.RotatingWord> for any Language
    </div>
  );
};

export default RotatingText;
